import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { selectCompanyState } from './company.selectors';

export const selectUserState = (state: AppState) => state.users;

export const selectUsers = createSelector(selectUserState, selectCompanyState, ({ entries, searchFilter }) =>
  entries.filter((user) =>
    Object.values(user).some((entry) => String(entry).toLowerCase().includes(searchFilter.toLowerCase()))
  )
);

export const selectOpsUsers = createSelector(selectUserState, ({ opsTeam, searchFilter }) =>
  opsTeam.filter((user) =>
    Object.values(user).some((entry) => String(entry).toLowerCase().includes(searchFilter.toLowerCase()))
  )
);

export const selectUsersLoading = createSelector(selectUserState, ({ loading }) => loading);

export const selectAppUsers = createSelector(
  selectUserState,
  selectCompanyState,
  ({ entries, searchFilter }, { entries: companies }) =>
    entries
      .filter((user) =>
        Object.values(user).some((entry) => String(entry).toLowerCase().includes(searchFilter.toLowerCase()))
      )
      .map((data) => {
        const company = data?.company ? companies.find(({ uuid }) => uuid === data?.company) : null;
        return { ...data, company: company?.legalName, companyUUID: company?.uuid };
      })
);